import image from "./images/image1.jpg"
function App() {
  return (
    <div className="wrapper">
      <img className="sourceimg" src={image}/>
    </div>
  );
}

export default App;
